import React, { Component } from 'react';
import { Hero, MixedFeed, ListModule, MoreContent, Ad } from '@yes/components';
import PropTypes from 'prop-types';

class Home extends Component {
    constructor(props) {
        super(props);
        this.props = props;
    }

    renderStatsModule(statsModule, className, key) {
        const innerHtml = { __html: statsModule };
        return <div className={className} key={key} dangerouslySetInnerHTML={innerHtml} />;
    }

    render() {
        const {
            className,
            heroData,
            mixedFeed,
            moreItems,
            moduleData,
            skinAdProps,
            statsModules,
            scoreboardModule
        } = this.props;

        // Give content control over hiding stats modules
        const showStatModules = statsModules && statsModules.every((element) => element !== '');
        return (
            <div className={className}>
                <div id="skin-ad">
                    <Ad {...skinAdProps} />
                </div>
                <Hero className={'hero__container'} {...heroData} />
                <div className={'section__container widgets__container'}>
                    {scoreboardModule && (
                        <div className={'scoreboard__module-row'}>
                            {this.renderStatsModule(scoreboardModule, 'scoreboard-module', 'scoreboard-module')}
                        </div>
                    )}
                    {showStatModules && (
                        <div className={'stats__module-row'}>
                            {statsModules.map((statsModule, key) => {
                                return this.renderStatsModule(statsModule, 'stats-module', `stats-${key}`);
                            })}
                            {statsModules && <Ad sectionName={'home'} targeting={{ pos: 1 }} slotSize={[300, 250]} />}
                        </div>
                    )}
                    <MixedFeed title={'Top Stories'} mediaItems={mixedFeed} sectionName={'home'} containsAd={true} />
                    <MoreContent {...moreItems} />
                    <div className={'module__row'}>
                        {moduleData.map((moduleData, key) => {
                            return <ListModule key={key} className={'module-component'} {...moduleData} />;
                        })}
                        <Ad sectionName={'home'} targeting={{ pos: 3 }} slotSize={[300, 250]} />
                    </div>
                </div>
            </div>
        );
    }
}

Home.propTypes = {
    className: PropTypes.string,
    heroData: PropTypes.object,
    mixedFeed: PropTypes.array,
    moreItems: PropTypes.object,
    moduleData: PropTypes.array,
    server: PropTypes.bool,
    skinAdProps: PropTypes.object,
    scoreboardModule: PropTypes.string,
    statsModules: PropTypes.array
};

Home.defaultProps = {
    className: 'home__body',
    heroData: {},
    mixedFeed: [],
    moreItems: {},
    moduleData: [],
    server: false,
    scoreboardModule: '',
    skinAdProps: {},
    statsModules: []
};

export default Home;
