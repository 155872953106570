const graceNote = (function(g, r, a, c, e) {
    if (!r) {
        return;
    }
    r.gnsWidgetObject = e;
    r[e] =
        r[e] ||
        function(n, o, t) {
            t = Object.prototype.toString.call(t) === '[object Array]' ? t : [t];
            (r[e].q = r[e].q || []).push([n, o, t]);
        };
    r[e].c = r[e].c || { l: a };
    r.c =
        r.c ||
        function(fs) {
            r[e].c.featureSwitch = fs;
        };
    var x = g.getElementsByTagName('script')[0],
        y = g.createElement('script'),
        z = g.createElement('script');
    y.async = 0;
    y.src = c;
    z.async = 1;
    z.src = a;
    x.parentNode.insertBefore(y, x);
    x.parentNode.insertBefore(z, x);
})(
    document,
    window,
    'https://widgets.sports.gracenote.com/gns.sdk.loader.js',
    'https://widgets.sports.gracenote.com/featureSwitches.jsonp',
    'gnsWidget'
);
gnsWidget('widgets', 'create'); // eslint-disable-line no-undef

export default graceNote;
