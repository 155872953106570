/* eslint-disable no-unused-vars */
import React from 'react';
import { hydrate } from 'react-dom';

import Home from './components/Home';
import '../../../scripts/gracenote';

const initData = JSON.parse(unescape(window.INIT_DATA));

hydrate(<Home {...initData} server={false} />, document.getElementById('root'));
